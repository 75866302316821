import React from 'react';
// styles
import { LogoContainer, StyledText, Wrapper } from './styled';
// types
import { DomainCellProps } from './types';

const DefaultLogo = () => (
  <svg width='35' height='36' viewBox='0 0 35 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.29167 31.125H27.7083C29.3192 31.125 30.625 29.8192 30.625 28.2083V7.79167C30.625 6.18084 29.3192 4.875 27.7083 4.875H7.29167C5.68084 4.875 4.375 6.18084 4.375 7.79167V28.2083C4.375 29.8192 5.68084 31.125 7.29167 31.125ZM7.29167 31.125L23.3333 15.0833L30.625 22.375M14.5833 12.8958C14.5833 14.104 13.604 15.0833 12.3958 15.0833C11.1877 15.0833 10.2083 14.104 10.2083 12.8958C10.2083 11.6877 11.1877 10.7083 12.3958 10.7083C13.604 10.7083 14.5833 11.6877 14.5833 12.8958Z'
      stroke='#E7ECF8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const DomainCell: React.FC<DomainCellProps> = ({ data: { getValue } }) => {
  const domainName = getValue();

  return (
    <Wrapper>
      <LogoContainer>
        <img
          src={`https://logo.clearbit.com/${domainName}`}
          alt={domainName}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.style.display = 'none';
            const parent = target.parentElement;
            if (parent) {
              const defaultLogo = document.createElement('div');
              defaultLogo.innerHTML = DefaultLogo().props.children;
              parent.appendChild(defaultLogo);
            }
          }}
        />
      </LogoContainer>
      <StyledText variant='body2'>{domainName}</StyledText>
    </Wrapper>
  );
};

export default DomainCell;
