// material-ui
import { Box, styled, Typography } from '@mui/material';
// styles
import { gray } from 'themes/colors';

export const Wrapper = styled(Box)`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const LogoContainer = styled(Box)`
  width: 92px;
  height: 52px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const StyledText = styled(Typography)`
  font-weight: 500;
  line-height: 20px;
  color: ${gray[900]};
`;
