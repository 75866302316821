import moment from 'moment';
import { CalculateReportParams, ReportStatus } from 'services/api/types/reports';
import { costMetrics, defaultMetrics, rateMetrics } from './Create/components/FilterPreferences/constants';
import { FormData } from './Create/components/FilterPreferences/types';

export const getChipDataByStatus = (
  status: ReportStatus,
): { label: string; color: 'success' | 'default' | 'error' } => {
  switch (status) {
    case ReportStatus.COMPLETED: {
      return {
        label: 'Completed',
        color: 'success',
      };
    }
    case ReportStatus.PENDING: {
      return {
        label: 'Pending',
        color: 'default',
      };
    }
    case ReportStatus.ERROR: {
      return {
        label: 'Error',
        color: 'error',
      };
    }
    default: {
      return {
        label: 'Unknown',
        color: 'default',
      };
    }
  }
};

export const getMetricSymbol = (key: string, currencyTag = '$') => {
  if (costMetrics.find((item) => item.value === key)) {
    return currencyTag;
  }

  if (rateMetrics.find((item) => item.value === key)) {
    return '%';
  }

  return '';
};

export const prepareRequestData = (values: FormData): CalculateReportParams => {
  const filters = {
    ...(values?.campaignIds.length && { campaignIds: values.campaignIds }),
    ...(values?.lineIds.length && { lineIds: values.lineIds }),
    ...(values?.lineCreativeIds.length && { creativeIds: values.lineCreativeIds }),
    ...(values.countries.length && { country: values.countries }),
  };

  return {
    metrics: [...new Set([...defaultMetrics, ...values.displayCharts, ...values.displayColumns])],
    dimensions: [values.dimensions as string],
    startDate: moment(values.startDate).startOf('day').utcOffset(0, true).toISOString(),
    endDate: moment(values.endDate).endOf('day').utcOffset(0, true).toISOString(),
    filters,
    rollUp: values.timeSplit,
    displayChart: {
      show: values.displayCharts,
    },
    displayTable: {
      show: values.displayColumns,
    },
  };
};
