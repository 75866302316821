import { AccessControl, AccessRuleAction } from 'services/api/types/access-control';

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^ \w])/;

export const convertBase64 = (file: File | Blob) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertURLToBase64 = async (url: string) => {
  const res = await fetch(url);
  const blob = await res.blob();

  return convertBase64(blob);
};

export const normalizeUrls = (urls: any) =>
  urls.reduce((accumulator: any, item: any) => {
    if (item.type === 'impressionPixels') {
      if (accumulator[item.type]) {
        accumulator[item.type].push(item.url);
      } else {
        accumulator[item.type] = [item.url];
      }
    } else {
      accumulator[item.type] = item.url;
    }
    return accumulator;
  }, {});

export const transformUrlsToArray = (obj: any) => {
  const result = [];

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      for (const url of obj[key] as string[]) {
        console.log({ url });
        if (url) {
          result.push({
            type: key,
            url: url,
          });
        }
      }
    } else if (typeof obj[key] === 'string') {
      result.push({
        type: key,
        url: obj[key] as string,
      });
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      console.log('transformUrlsToArray', key, obj[key], obj);
      result.push({
        type: key,
        url: obj[key].url as string,
        readonly: obj[key].readonly as boolean,
      });
    }
  }

  return result;
};

export const formatNumber = (value: number | null, options?: Intl.NumberFormatOptions) =>
  value !== null ? new Intl.NumberFormat('en-US', options).format(value) : null;

export const formatCurrency = (value: number | null | undefined, currency = 'USD') =>
  value !== null && value !== undefined
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      }).format(value)
    : null;

export const convertToNumber = (value: string | number | null) =>
  value ? (typeof value === 'number' ? value : Number(value.replaceAll(/,/g, ''))) : null;

export const omit = <T extends object>(keys: string[], obj: T): T =>
  (keys as any).reduce((a: Partial<T>, e: keyof T) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [e]: omitted, ...rest } = a;

    return rest;
  }, obj);

export const compareArrays = (a: any[], b: any[]) => {
  if (a.length !== b.length) return false;
  const elements = new Set([...a, ...b]);
  for (const x of elements) {
    const count1 = a.filter((e) => e === x).length;
    const count2 = b.filter((e) => e === x).length;
    if (count1 !== count2) return false;
  }
  return true;
};

export const prepareDownloadCSVData = (data: Array<{ [key: string]: any }>) => {
  if (data.length) {
    return [Object.keys(data[0]).join(','), ...data.map((item) => Object.values(item).join(','))].join('\n');
  }
  return [];
};

export function downloadCSV(data: any, fileName: string) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function checkRouteAccess(accessControl: AccessControl | null, action?: AccessRuleAction): boolean {
  if (accessControl === null || !action) {
    return true;
  }

  return Boolean(accessControl.accessRules.find((item) => item.action === action)?.isAllowed);
}
