import { FC, useMemo, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import moment from 'moment';
import { CartesianGrid, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { metricsLabels } from 'pages/Analytics/DeepDataReports/Create/constants';
import { getMetricSymbol } from 'pages/Analytics/StandardReports/helpers';
import { formatNumber } from 'utils';
import { colors } from '../../constants';
import { LineChartWrapper, StyledLabel, StyledLineChart } from '../../styled';

type MetricByDimensionProps = {
  data: any[];
  metric: string;
  isLoading: boolean;
};

const DefaultLogo = () => (
  <svg width='35' height='36' viewBox='0 0 35 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.29167 31.125H27.7083C29.3192 31.125 30.625 29.8192 30.625 28.2083V7.79167C30.625 6.18084 29.3192 4.875 27.7083 4.875H7.29167C5.68084 4.875 4.375 6.18084 4.375 7.79167V28.2083C4.375 29.8192 5.68084 31.125 7.29167 31.125ZM7.29167 31.125L23.3333 15.0833L30.625 22.375M14.5833 12.8958C14.5833 14.104 13.604 15.0833 12.3958 15.0833C11.1877 15.0833 10.2083 14.104 10.2083 12.8958C10.2083 11.6877 11.1877 10.7083 12.3958 10.7083C13.604 10.7083 14.5833 11.6877 14.5833 12.8958Z'
      stroke='#E7ECF8'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const CustomTooltip = ({ active, payload, label, metric, metricSymbol1 }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box sx={{ bgcolor: 'background.paper', p: 1, borderRadius: 1, boxShadow: 3, zIndex: 99999 }}>
        <Box sx={{ mb: 1 }}>{moment(label).format('D MMM YYYY')}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {payload.map((entry: any, index: number) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box sx={{ width: 55, height: 35 }}>
                <img
                  src={`https://logo.clearbit.com/${entry.name}`}
                  alt={entry.name}
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.style.display = 'none';
                    const parent = target.parentElement;
                    if (parent) {
                      const defaultLogo = document.createElement('div');
                      defaultLogo.innerHTML = DefaultLogo().props.children;
                      parent.appendChild(defaultLogo);
                    }
                  }}
                />
              </Box>
              <Box sx={{ color: entry.color }}>
                {entry.name}:{' '}
                {metric === 'ctr'
                  ? `${entry.value.toFixed(2)}${metricSymbol1}`
                  : `${formatNumber(entry.value)}${metricSymbol1}`}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
  return null;
};

const Chart: FC<MetricByDimensionProps> = ({ data, isLoading, metric }) => {
  const [hiddenLines, setHiddenLines] = useState<string[]>([]);

  const metricSymbol1 = getMetricSymbol(metric);

  const handleLegendClick = (event: any) => {
    setHiddenLines((prev) => {
      if (prev.includes(event.payload.name)) {
        return [...prev.filter((item) => item !== event.payload.name)];
      }

      return [...prev, event.payload.name];
    });
  };

  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' height={450} alignItems='center'>
        <CircularProgress size={30} />
      </Box>
    );
  }
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' zIndex={10000}>
      <Box display='flex' px={3}>
        <StyledLabel>
          {metricSymbol1 ? `${metricsLabels[metric]}, ${metricSymbol1}` : metricsLabels[metric]}
        </StyledLabel>
      </Box>

      <LineChartWrapper>
        <ResponsiveContainer width='100%' height='100%'>
          <StyledLineChart
            width={650}
            margin={{
              top: 15,
              right: 28,
              left: 28,
              bottom: 5,
            }}
          >
            <XAxis
              dataKey='date'
              allowDuplicatedCategory={false}
              tickFormatter={(date) => moment(date).format('D MMM')}
              type='number'
              domain={['dataMin', 'dataMax']}
              interval={0}
              scale='time'
            />

            <YAxis
              tickCount={5}
              tickMargin={5}
              interval={0}
              axisLine={false}
              tickLine={false}
              type='number'
              domain={[0, 'dataMax']}
              tickFormatter={(value) =>
                metric === 'ctr' ? `${value.toFixed(2)}${metricSymbol1}` : `${formatNumber(value)}${metricSymbol1}`
              }
            />

            <CartesianGrid
              verticalCoordinatesGenerator={(props) => [props.xAxis.x, props.xAxis.x + props.xAxis.width]}
            />

            <Tooltip content={<CustomTooltip metric={metric} metricSymbol1={metricSymbol1} />} />
            <Legend iconType='plainline' iconSize={20} onClick={handleLegendClick} />

            {data.map(([key, value], i) => {
              return (
                <Line
                  connectNulls
                  dataKey={metric}
                  data={value}
                  name={key}
                  key={`${key} ${metric}`}
                  stroke={colors[i]}
                  dot={{ fill: colors[i] }}
                  hide={hiddenLines.includes(key)}
                  color={colors[i]}
                />
              );
            })}
          </StyledLineChart>
        </ResponsiveContainer>
      </LineChartWrapper>
    </Box>
  );
};

export default Chart;
